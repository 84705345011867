import * as Sentry from '@sentry/vue';

export default defineNuxtPlugin(() => {
  const runTimeConfig = useRuntimeConfig();
  useGqlError((err) => {
    for (const gqlError of err.gqlErrors) {
      Sentry.captureException(gqlError);

      if (runTimeConfig.public.environmentName !== 'production') {
        console.error('[nuxt-graphql-client] [GraphQL error]', {
          client: err.client,
          statusCode: err.statusCode,
          operationType: err.operationType,
          operationName: err.operationName,
          gqlError
        });
      }
    }
  });
});
  