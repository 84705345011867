<template>
  <Overlay
    :overlay-active="overlayActive"
    class="z-overlay"
    @close-overlay="$emit('close-modal')"
  >
    <div
      class="flex flex-col h-full w-full relative modal-content-wrapper"
      :class="{
        'md:p-56 p-0 md:items-end md:justify-end md:w-auto w-full justify-start': offsetModal,
        'md:justify-center items-center': !offsetModal,
      }"
      @mousedown.self="$emit('close-modal'), close()"
    >
      <transition name="slide" mode="out-in">
        <div
          v-if="showContent"
          class="bg-white relative w-full overflow-y-visible modal-content overflow-auto"
          :style="background"
          :class="{
            'mx-auto': !offsetModal,
            'md:max-w-460': !newsletterModal && !withImage && !wide,
            'md:max-w-420': newsletterModal && !withImage && !wide,
            'md:max-w-840 flex': withImage,
            'md:max-w-840': wide,
            '!overflow-visible': wrongCountryModal
          }"
        >
          <div
            class="close-modal-button absolute top-24 right-24 w-24 h-24 cursor-pointer select-none flex justify-center items-center"
            @click="$emit('close-modal')"
          >
            <img
              src="~assets/icons/close.png"
              class="cursor-pointer select-none"
              height="20"
              width="20"
            />
          </div>
          <slot></slot>
        </div>
      </transition>
    </div>
  </Overlay>
</template>

<script setup lang="ts">
import Overlay from '~/components/body/Overlay.vue';

const overlayActive = ref(false);
const showContent = ref(false);

defineProps<{
  offsetModal?: boolean,
  newsletterModal?: boolean,
  withImage?: boolean,
  background?: string,
  wrongCountryModal?: boolean,
  wide?: boolean
}>();

const init = () => {
  setTimeout(() => (overlayActive.value = true), 50);
  setTimeout(() => (showContent.value = true), 100);
};

init();

onMounted(() => {
  document.body.classList.add('locked');
});

const close = () => {
  document.body.classList.remove('locked');
};

</script>

<style scoped>
.slide-enter-active {
  transition: all 0.2s ease;
}
.slide-leave-active {
  transition: all 0.2s;
}
.slide-enter-from,
.slide-leave-to {
  opacity: 0.15;
  transform: translateY(-50px);
  @screen md {
    transform: translateY(50px);
  }
}
</style>
