import { useGlobalContentStore } from '~/store/globalContent';
import * as Sentry from '@sentry/vue';
import { storeToRefs } from 'pinia';

const lastError = ref(null) as any;
const lastErrorCode = ref<number | null>(null);
const lastResponse = ref(null) as any;
const pending = ref(false);

export default function useApiFetch() {
  const apiGet = async<T>(url: string, additionalHeaders? : null | object) : Promise<T> => {
    return req<T>(url, 'GET', null, additionalHeaders);
  };

  const apiPost = async<T>(url: string, body?: null | object, additionalHeaders? : null | object) : Promise<T> => {
    return req<T>(url, 'POST', body, additionalHeaders);
  };

  const apiPut = async<T>(url: string, body?: null | object, additionalHeaders? : null | object) : Promise<T> => {
    return req<T>(url, 'PUT', body, additionalHeaders);
  };

  const apiDelete = async<T>(url: string, additionalHeaders? : null | object) : Promise<T> => {
    return req<T>(url, 'DELETE', null, additionalHeaders);
  };

  const req = async<T>(url: string, method: string, body?: null | object, additionalHeaders? : null | object) : Promise<T | any> => {
    pending.value = true;

    lastResponse.value = null;
    lastError.value = null;
    lastErrorCode.value = null;
    const globalContentStore = useGlobalContentStore();
    const runtimeConfig = useRuntimeConfig();
    const lang = useNuxtApp().$lang;
    const { requestVerificationToken } = storeToRefs(globalContentStore);

    try {
      if(method == 'POST') {
        if (!globalContentStore.getTheAntiForgeryToken) {
          const result = await $fetch(`${runtimeConfig.public.apiUrl}website/xsrf/token`);
          if (result) {
            requestVerificationToken.value = result;
          }
        }
      }
      
      let headers : any = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Accept-Language': globalContentStore.getAcceptLanguage,
        'RequestVerificationToken': globalContentStore.getTheAntiForgeryToken,
      };
      if(process.server){
        headers['X-Epi-Host'] = useRequestHeaders()['x-forwarded-host'];
      }
      const opts = {
        headers: headers,
        method: method,
        baseURL: process.client ? runtimeConfig.public.apiUrl : runtimeConfig.apiUrl,
        key: url
      } as any;

      if (body) {
        opts.body = JSON.stringify(body);
      }

      if (additionalHeaders) {
        opts.headers = Object.assign(opts.header, additionalHeaders);
      }

      const { data, error } = await useFetch<T>(
        url,
        opts
      );

      if (error?.value?.data) {
        if (error.value?.statusCode === 400 && error.value.data) {
          lastError.value = error?.value.data;
          lastErrorCode.value = error.value?.statusCode;
        } else {
          lastError.value = lang('sharedResources', 'genericError');
          lastErrorCode.value = error.value?.statusCode || null;
        }
      } else {
        lastResponse.value = data.value;
        return data.value as T;
      }

    } catch (e) {
      Sentry.captureException(e);
    } finally {
      pending.value = false;
    }
  };

  return {
    apiGet,
    apiPost,
    apiPut,
    apiDelete,
    lastError,
    lastErrorCode,
    lastResponse,
    pending,
  };
}
