<template>
  <div>
    <transition name="fade">
      <div
        v-if="show"
        class="overlay fixed top-0 right-0 bottom-0 left-0 bg-black z-modal bg-opacity-80"
        @click="$emit('close')"
      ></div>
    </transition>
    <div>
      <div
        class="transition duration-300 ease-in-out overflow-x-auto fixed transform right-0 top-0 w-320 flex flex-col h-full md:w-420 bg-white z-modal pb-112"
        :class="{
          'translate-x-0': show,
          'translate-x-320 md:translate-x-420': !show,
        }"
      >
        <div class="p-16 md:p-24 flex items-center">
          <ClientOnly>
            <fa
              :icon="['fal', 'times']"
              class="sub-heading-md-text cursor-pointer"
              @click="$emit('close')"
            ></fa>
          </ClientOnly>
        </div>
        <div class="p-16 md:p-20 pb-112">
          <p class="sub-heading-lg-text">
            {{ $lang('searchResources', 'heading') }}
          </p>
          <div
            class="flex items-center border border-grey600 rounded-t rounded-b mt-12 px-16"
          >
            <SearchInput
              :id="'searchInput'"
              ref="searchBox"
              :placeholder="$lang('searchResources', 'placeholder')"
              :type="'text'"
              :form-input="true"
              :delay-submit="false"
              @submitInput="search"
            />
          </div>

          <div v-if="!query.length" class="mt-28">
            <p class="sub-heading-sm-text mb-8">
              {{ $lang('searchResources', 'popularSearchesLabel') }}
            </p>
            <p v-for="item in popularLinks" :key="`searchLink-${item.text}`">
              <nuxt-link
                :to="item.href"
                :target="item.target"
                :title="item.title"
                class="underline"
                @click.native="$emit('close')"
                >{{ item.text }}</nuxt-link
              >
            </p>
          </div>

          <div v-if="searchResult && Object.keys(searchResult).length && query.length">
            <p class="base-text text-grey600 my-8">
              {{ searchResult.totalHits }} {{ $lang('searchResources', 'hits') }}
            </p>

            <div v-if="searchResult.products.length" class="mt-24">
              <nuxt-link
                v-for="product in searchResult.products.slice(0, 4)"
                :key="product.id"
                :to="product.url"
                class="flex items-center mb-16"
                @click.native="$emit('close')"
              >
                <div class="flex-shrink-0">
                  <nuxt-img
                    preset="productImage"
                    loading="lazy"
                    class="w-40"
                    :src="`${product.imageUrl}?w=80`"
                    :alt="product.name"
                  />
                </div>
                <div class="mr-auto ml-12 pr-12 flex flex-col">
                  <p class="small-base-text">{{ product.name }}</p>
                  <span class="small-base-regular-text text-grey600">{{
                    product.description
                  }}</span>
                </div>
                <div v-if="purchaseEnabled" class="small-base-text flex-shrink-0">
                  {{ product.variants[0].price }}
                </div>
              </nuxt-link>
            </div>

            <div v-if="searchResult.content.length" class="mt-32">
              <p class="sub-heading-sm-text mb-12">
                {{ $lang('searchResources', 'content') }}
              </p>
              <p v-for="item in searchResult.content.slice(0, 3)" :key="item.url">
                <nuxt-link
                  :to="item.url"
                  class="underline"
                  @click.native="$emit('close')"
                >
                  {{ item.name }}
                </nuxt-link>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div
        class="transition duration-300 ease-in-out bg-white fixed bottom-0 pt-16 right-0 w-320 md:w-420 z-modal"
        :class="{
          'translate-x-0': show,
          'translate-x-320 md:translate-x-420': !show,
        }"
      >
        <nuxt-link
          v-if="searchResult && Object.keys(searchResult).length && searchResult.totalHits > 0"
          class="btn btn--md btn--wide btn--dark-theme m-16 mt-auto flex-shrink-0 mb-32"
          :to="searchResultUrl + '?query=' + query"
          @click.native="$emit('close')"
          >{{ $lang('searchResources', 'showAllHits') }}</nuxt-link
        >
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { type ISearchResults } from '~/models/api-types';
import { Sizes } from '~/constants/button-layouts';
import SearchInput from '~/components/form-elements/SearchInput.vue';
import { usePageContentStore } from '~/store/pageContent';
import { useGlobalContentStore } from '~/store/globalContent';
import * as Sentry from '@sentry/vue'

const globalContentStore = useGlobalContentStore();
const pageStore = usePageContentStore();

const config = useGlobalContentStore().config;

const { apiGet } = useApiFetch();
const { $lang } = useNuxtApp();

const props = defineProps<{
  show: boolean,
}>();

const query = ref('');
const page = ref(1);
const pageSize = ref(7);
const isLoading = ref(false);
const searchResult = ref();

const popularLinks = computed(() => {
  const { currentMarket } = config;
  return currentMarket?.popularLinks ?? [];
});

const searchResultUrl = computed(() => {
  return config.currentMarket?.searchResultsPageUrl;
});

const purchaseEnabled = computed(() => {
  return config.currentMarket?.enablePurchases;
});

const searchBox = ref();
watch(
  () => props.show,
  () => {
    if (props.show) {
      document.body.classList.add('locked');
      (searchBox.value as any).focus();
    } else {
      document.body.classList.remove('locked');
    }
  }
);

const search = async(input: string) => {
  query.value = input;
  const { currentMarket, language } = config;

  (searchBox.value as any).startLoading();

  const searchTerm = `search/?language=${language}&countryCode=${currentMarket?.countryCode}&query=${input}`;
  const search = await apiGet<ISearchResults>(searchTerm);

  searchResult.value = search;

  (searchBox.value as any).stopLoading();
};
</script>
<style scoped>
.filter-value:hover:not(.selected) {
  @apply bg-grey500;
}
</style>
