import { defineStore } from 'pinia';
import { useGlobalContentStore } from './globalContent';
import * as Sentry from '@sentry/vue';
import { IAgeVerificationStatus } from '~/models/api-types';

const { apiGet, apiPut, apiPost } = useApiFetch();

type State = {
  isAgeConfirmed: boolean;
  postalCode: string;
  kcoIsInjected: string;
  isInitialPostalCodeCheck: boolean;
  onCheckoutPage: boolean;
};

export const useCheckoutStore = defineStore('checkout', {
  state: (): State => ({
    isAgeConfirmed: false,
    postalCode: '',
    kcoIsInjected: '',
    isInitialPostalCodeCheck: true,
    onCheckoutPage: false,
  }),
  actions: {
    setOnCheckoutPage() {
      this.onCheckoutPage = true;
    },
    setNotOnCheckoutPage() {
      this.onCheckoutPage = false;
    },
    setPostalCode(postalCode: string): void {
      const postalCookie = useCookie('verifiedPostalCode');
      postalCookie.value = postalCode;
      this.postalCode = postalCode;
    },
    setAgeIsConfirmed(): void {
      this.isAgeConfirmed = true;
    },
    async updatePostalCode(
      { postalCode, purchaseType }
    ): Promise<void> {
      const config = useGlobalContentStore().config;
      const { currentMarket, language } = config;
      const { $event } = useNuxtApp();

      if (this.isInitialPostalCodeCheck) {
        this.isInitialPostalCodeCheck = false;
        return;
      }

      if (
        this.postalCode &&
        postalCode.replace(/\D/g, '') !== this.postalCode.replace(/\D/g, '')
      ) {
        await apiPut(
          `checkout/buyer/postalcode?language=${language}&countryCode=${currentMarket?.countryCode}`,
          {
            postalCode,
            purchaseType,
          }
        );
        this.postalCode = postalCode;
        $event('scrollToDelivery');
      }
    },
    kcoInjected() {
      this.kcoIsInjected = 'true';
    },
    suspendKco() {
      const win = window as any;
      if (win._klarnaCheckout && this.kcoIsInjected) {
        win._klarnaCheckout(function (api: { suspend: () => void }) {
          api.suspend();
        });
      }
    },
    resumeKco() {
      const win = window as any;
      if (win._klarnaCheckout && this.kcoIsInjected) {
        win._klarnaCheckout(function (api: { resume: () => void }) {
          api.resume();
        });
      }
    },
    async update(purchaseType: string) {
      if (!this.kcoIsInjected || !this.onCheckoutPage) {
        return;
      }
      this.suspendKco();
      const config = useGlobalContentStore().config;
      const { currentMarket, language } = config;
      // the endpoint triggers an update to KCO form thru storm
      const query = `checkout/form?language=${language}&countryCode=${currentMarket?.countryCode}&purchaseType=${purchaseType}`;
      await apiGet(query);
      this.resumeKco();
    },
    async verifyAgeIsConfirmed(
      { isBankId, purchaseType }: {isBankId: boolean, purchaseType: string }
    ) {
      const config = useGlobalContentStore().config;
      const { currentMarket, language } = config;
  
      const query = `checkout/ageverification?language=${language}&countryCode=${currentMarket?.countryCode}&purchaseType=${purchaseType}`;
      const res = await apiGet<IAgeVerificationStatus>(query);
      const postalCookie = useCookie('verifiedPostalCode');
      const postalCode = postalCookie?.value;

      this.isAgeConfirmed = res?.isVerified;

      if (res?.isVerified && postalCode) {
        this.postalCode = postalCode.toString();
      }

      if (isBankId && res?.isVerified) {
        setTimeout(() => {
          window.dataLayer?.push({
            event: 'checkoutinfo',
            eventCategory: 'checkout',
            eventAction: 'VerIDtimeout',
            eventLabel: 1,
          });
          this.isAgeConfirmed = false; 
        }, res.expiresInMs);
      }
    },
  },
});
