/* eslint-disable camelcase */

import { defineStore } from 'pinia';
import { useGlobalContentStore } from '~/store/globalContent';
import { useVoyadoStore } from '~/store/voyado';
import * as Sentry from '@sentry/vue'
import { storeToRefs } from 'pinia';
import type { ILoginResult } from '~/models/api-types';

export const useUserStore = defineStore('userStore', {
  state: () => ({
    isVerified: '',
  }),
  actions: {
    async logIn(userDetails: {
      email: string,
      password: string,
      rememberMe: boolean,
    }) {
      const config = useGlobalContentStore().config;
      const voyadoStore = useVoyadoStore();
      const { apiPost, lastError } = useApiFetch();
      
      const res = await apiPost<ILoginResult>(
        `user/session?countryCode=${config.currentMarket?.countryCode}&language=${config.language}`,
        userDetails
      );

      const globalContentStore = useGlobalContentStore();
      const { requestVerificationToken } = storeToRefs(globalContentStore);
      const runTimeConfig = useRuntimeConfig();
      if (requestVerificationToken) {
        requestVerificationToken.value = await $fetch<string>(`${runTimeConfig.public.apiUrl}website/xsrf/token`);
      }

      if (!lastError.value && res.success) {
        await voyadoStore.setContactIdFromEmail(userDetails.email);
        await this.checkIsVerified();
      }

      return res;
    },
    async logOut() {
      const globalContentStore = useGlobalContentStore();
      const config = useGlobalContentStore().config;
      try {
        const res = await $fetch(`/api/user/session?countryCode=${config.currentMarket?.countryCode}&language=${config.language}`, {
          method: 'DELETE',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Accept-Language': globalContentStore.getAcceptLanguage,
            'RequestVerificationToken': globalContentStore.getTheAntiForgeryToken,
          },
        });

        window.location.href = config.currentMarket?.url || '/';
      } catch (e) {
        Sentry.captureException(e);
      }
    },
    async checkIsVerified() {
      const config = useGlobalContentStore().config;
      const { apiGet } = useApiFetch();
      const query =  `ageverification/is-verified?countryCode=${config.currentMarket?.countryCode}&language=${config.language}`
      const res = await apiGet(query);
      this.isVerified = res?.isVerified;
    },
  },
  getters: {
  },
});
