<template>
  <div
    class="mb-32 small-base-text cursor-pointer select-none mt-24"
    :class="{
      'text-grey800': colorScheme === 'light',
      'text-grey200': colorScheme === 'dark',
    }"
    @click="openMarketModal"
  >
    <ClientOnly><fa class="mr-8" :icon="['fas', 'map-marker-alt']"></fa></ClientOnly>
    {{ config.currentMarket?.country }}
  </div>
</template>
<script setup lang="ts">
import { useGlobalContentStore } from '~/store/globalContent';
const { $event } = useNuxtApp();
const config = useGlobalContentStore().config;
interface IProps {
  colorScheme?: string,
}

withDefaults(defineProps<IProps>(), {
  colorScheme: 'light', 
});

const openMarketModal = () => {
  $event('open-market-modal');
};
</script>
