import * as Sentry from '@sentry/vue';
export default defineNuxtPlugin((nuxtApp) => {
  const router = useRouter();
  const { public: { sentry } } = useRuntimeConfig();
  if (!sentry.dsn) {
    return;
  }
  Sentry.init({
    app: nuxtApp.vueApp,
    dsn: sentry.dsn,
    environment: sentry.environment,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: ['localhost', /^\//],
      }),
      new Sentry.Replay({
        blockAllMedia: false,
      }),
    ],
    tracesSampleRate: 0.02,
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 0.02,
  });
});
